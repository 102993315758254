#shopping-cart {
  .shopping-card {
    display: flex;
    width: 96%;
  }

  // .shopping-card .shopping-item{
  //   width: 100%;
  //   margin: 0px;
  //   padding: 0px;
  // }

  .empty-cart ion-img {
    max-height: 40%;
    max-width: 40%;
    margin: 36px 50px;
    //pointer-events: none;
    left: 50%;
    transform: translateX(40%);
  }
  
  .shopping-card  ion-img {
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 120px;
    max-width: 160px;
    max-height: 140px;
    //margin-top: px;
    // transform: translateX(-16%);
     //transform: translateY(3%);
  }

  // ion-card {
  //   padding: 0px;
  //   left: 0ex;
  //   margin: 1 2px;
  // }

  .shopping-card  ion-row {
    margin: 0 -15px;
  }

  .shopping-card ion-col {
    // border-color: black; 
    // border-width: .01em; 
    // border-style:solid; 
    width: 100%;
    height: 100%;
    padding: 0px;
    padding-top: 10px;
  }

  .text-ion-col{
    margin-left: 18px;
    padding-top: 0;
    margin-top: 0;
  }
  
  .text-ion-title{
    margin-left: 15px;
    margin-top: -8px;
    padding-top: 0;
    
  }

  .shopping-card .delete  {
    width: 35px;
    height: 30px;
    left: 12px;
    right: 0px;
    margin-top: -10px;

  }

  .image-ion-col{
    margin-left:  13px;
    height: 100%;
  }

  ion-title{
    max-width: 150px;
  }
  
  ion-button {
    width: 100%;
    height: 40px;
    position: relative;
  }

  ion-badge {
    position: absolute;
    z-index: 99;
    font-size: 7.5pt;
    top: -3px;
    right: 0;
  }

  .shopping-card ion-button {
    width: 35px;
    height: 30px;
    color: "white";
    left: 22px;
  }

  .fav-ion-button ion-button {
    width: 60px;
    height: 30px;
    color: "white";
    left: 15px;
  }
  .fav-ion-button {
    width: 70px;
    height: 30px;
    color: "white";
    //left: 3px;
  }
  .del-ion-button{
    text-align: right;
    margin-right: 5px;
    padding: 0em;
  }
  

  ion-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
  }

  .fav-icon{
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    top:-20px;
    width: 100%;
    background-color: white;
  }

  .shopping-card ion-card-header {
    padding: 2px;
    flex: 1 2 auto;
    text-align: left;
  }

  .shopping-card  ion-card-subtitle {
    padding: 5px;
    left: 20px;
  }

  .shopping-card  ion-card-title {
    left: 24px;
    font-size: 16px;
    font-weight: 550;
    color: var(--ion-color-step-600, #60646b);
  }

  .shopping-total  p {
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
    transform: translateX(2%);
    text-align: right;
    
    b {
      color: var(--ion-text-color, #60646b);
    }
  }
    
  .shopping-total  ion-col {
    padding-right: 20px;
    width: 100%;
  }

  .text {
    color: gray;
  }

  .count {
    color: rgb(44, 43, 43);
  }

  .no-favourite{
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
    text-align: center;
  }
}