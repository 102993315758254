#termsAndConditions-page {
  ion-toolbar {
    position: absolute;

    
    --background: transparent;
    --color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  .about-header {
    position: relative;

    width: 100%;
    height: 30%;
  }
  .nopage-image {
    position: relative;
    left: 30%;
    bottom: 0;
    right: 0;
    height: 100%;
  }
  .about-header .about-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
  }

  .about-info {
    position: absolute;
    margin-top: -10px;
    border-radius: 10px;
    background: var(--ion-background-color, #fff);
  }

  .about-info h3 {
    margin-top: 0;
  }

  .about-info ion-list {
    padding-top: 0;
  }

  .about-info p {
    line-height: 130%;

    color: var(--ion-color-dark);
  }

  .faq p {
    font-size: 14px;
    line-height: 1;
    //color: var(--ion-color-step-900, #346bca);
    transform: translateX(10%);
    text-align: justify;
    left: 5px;
    b {
      color: var(--ion-text-color, #000000);
    }
  }


}

