#introduction-page {
  ion-toolbar {
    --background: transparent;
    --border-color: transparent;
  }

  .swiper-slide {
    display: block;
  }

  .slide-title {
    margin-top: 2.8rem;
  }

  .slide-image {
    max-height: 50%;
    max-width: 60%;
    margin: 36px 0;
    pointer-events: none;
  }

  ion-button {
    margin-right: 15px;
  }

  b {
    font-weight: 500;
  }

  p {
    padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);

    b {
      color: var(--ion-text-color, #000000);
    }
  }

}