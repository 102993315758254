#item {
  .item-card {
    display: flex;
    width: 95%;
    margin-left: 5px;
    margin-right: 35px;
    // border-color: black; 
    // border-width: .01em; 
    // border-style:solid; 
    padding: 0px;
  }

  /* Due to the fact the cards are inside of columns the margins don't overlap
   * properly so we want to remove the extra margin between cards
   */

  .main-col .item-card{
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    padding: 0px;
  }

  .item-col .item-card{
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    padding: 0px;
    justify-content: left;
  }
 
  ion-col /*:not(:last-of-type)*/ .item-card {
    margin-bottom: 0;
    display: flex;
    padding: 0px;
    justify-content: center;
  }

  
  ion-badge {
    position: absolute;
    z-index: 99;
    font-size: 7.5pt;
    top: 9px;
    right: 20px;
  }

  .item-badge {
    position: absolute;
    z-index: 100;
    font-size: 8pt;
    top: 3px;
    right: 3px;
    //justify-content: right;
  }

  .fast-badge {
    position: absolute;
    z-index: 100;
    font-size: 15pt;
    top: 3px;
            right: 70px;
            border-radius: 100%;
    background-color:rgb(73, 17, 31);
    //justify-content: right;
  }

  .top-button {
    position: absolute;
    z-index: 10;
    font-size: 10pt;
    width: 150px;
    height: 20px;
    top: 3px;
    right: 0px;
    border-radius: 20%;
  }
  
  ion-button {
    width: 60px;
    height: 60px;
    position: relative;
  }

  ion-button.custom-class::part(native) {
    overflow: visible;
  }
  
  .item-card .item-item {
    --min-height: 120px;
    --max-height: 120px;
    height: 190px;
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 0px;

  }

  .itemlist-item {
    width: 389px;
    display: flex;
    right: 15PX;
  }

 

  .item-card .item-item ion-thumbnail {
    // height: 100px;
    // width: 100px;
    //padding: 10px;
    margin-top: 0px;
    min-width: 100px;
    min-height: 100px;
    max-width: 120px;
    max-height: 120px;
  }

  .item-card .item-item ion-img {
    height: 120px;
    width: 100%;
    margin-bottom: 5px;
    
    // max-width: 120px;
    // max-height: 120px;
    //margin-right: 0px;

    //align-content:center;
    //flex-direction: column;

    //padding: 10px;
  }

  .item-card .item-item h2 {
    font-size: 18px;
    font-weight: 500;
    //letter-spacing: 0.02em;
    //line-height: 0;
    margin-bottom: 0px;
    margin-top: 0px;
    color: var(--ion-color-step-200, #3f3f3f);

  }

  .item-card .item-item p {
    font-size: 13px;
    letter-spacing: 0.02em;
    margin-top: 0px;
    margin-bottom: 0px;
    
  }

  .item-card ion-card-header {
    padding: 0;
  }

  .item-card ion-card-content {
    flex: 1 1 auto;
  }

  .ios ion-list {
    margin-bottom: 10px;
  }

  .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);
  }

  ion-searchbar {
    display: flex;
    align-self: center;
    margin-left: 3px;
    width: 100%;
  }
  
  .md ion-searchbar {
    display: flex;
    align-self: center;
    margin-left: 3px;
    width: 99%;
    color: var(--ion-color-step-600, #75787e);
  }

  .item-card-p {
    padding: 0px;
    font-size: 14px;
    //line-height: 1.5;
    color: var(--ion-color-step-600, #75787e);
    text-align: center;
    margin-bottom: 0px;
    b {
      color: var(--ion-text-color, #000000);
    }
  }

  .text {
    color: gray;
    margin-top: 0px;
  }
  .strike-through{
    text-decoration: line-through;
    color: var(--ion-color-step-600, #91959c);
  }

  .item-background img {
     width: 100%;
     height: 70%;
     margin-top: calc(-1 * var(--ion-safe-area-top));
  }

  .item-detail{
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    top:-20px;
    width: 100%;
    background-color: white;
  }

  .item-detail .count {
    color: gray;
    vertical-align: -webkit-baseline-middle;
    margin-left: 10px;
    margin-right: 10px;
  }

  .item-detail ion-button {
    width: 35px;
    height: 30px;
    color: "white";
  }

  .item-detail .add-to-cart {
    width: 170px;
    height: 30px;
    left: 15px;
  }

  .item-detail .cart {
    margin-top: 5px;
    margin-left: 10px;
  }

  .changeToast {
    --position: absolute;
    --top: 5em;
    .toast-wrapper {
      border-radius: 20px;
      border-width: 5px;
      border-style: double;
      --border-color: var(--ion-color-info);
    }
    .toast-message {
      white-space: pre;
    }
  }
}