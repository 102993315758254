#checkout {
  ion-card {
    padding-right: 10px;
  }

  .thank-you ion-img {
    max-height: 40%;
    max-width: 40%;
    margin: 36px 50px;
    //pointer-events: none;
    left: 50%;
    transform: translateX(40%);
  }

  .thank-you p {
    //padding: 0 40px;
    font-size: 15px;
    line-height: 1;
    color: var(--ion-color-step-600, #60646b);
    text-align: center;
    //transform: translateX(10%);
    b {
      color: var(--ion-text-color, #423f3f);
    }
  }

  .date  {
    font-size: 15px;
    line-height: 2;
    color: var(--ion-color-step-600, #796767);
    text-align: right;
    b {
      color: var(--ion-text-color, #796767);
    }
  }

  ion-col /*:not(:last-of-type)*/  {
    /*margin-bottom: 0;
    
    
    padding: 4px;*/
    // border-color: black; 
    // border-width: .01em; 
    // border-style:solid; 
    // margin-bottom : -1px;
    // background-color: lightgrey;
    // justify-content: right;
    width: 100%;
    height: 100%;
    
  }
  
  ion-label {
    text-align: right;
  }

  ion-button {
    width: 100%;
    height: 40px;
    position: relative;
  }

  ion-select {
    width: 100% !important;
    height: 2em;
    position: relative;
    margin-top: 1rem;
    background-color:#ffeeee;
  }

  ion-alert {
    min-width: 90%;
    width: 100%;
    min-height: auto;
    height: auto;
  }

  ion-checkbox {
    margin-right: 15px;
  }

  ion-badge {
    font-size: 9pt;
    right: 20px;
    background-color: rgb(82, 23, 33)b78;
  }

  p {
    //padding: 0 40px;
    font-size: 15px;
    line-height: 1;
    color: var(--ion-color-step-600, #60646b);
    text-align: right;
    //transform: translateX(10%);
    b {
      color: var(--ion-text-color, #423f3f);
    }
  }

  
 
}
