#about-page {
  ion-toolbar {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    //--color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  .about-header {
    position: relative;

    width: 100%;
    height: 30%;
  }
  .nopage-image {
    position: relative;
    top: 0;
    left: 30%;
    bottom: 0;
    right: 0;
    height: 100%;
  }
  .about-header .about-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
  }

  // .about-header .order {
  //   background-image: url("/assets/img/about/order.png");
  // }

  // .about-header .austin {
  //   background-image: url("/assets/img/about/austin.jpg");
  // }

  // .about-header .chicago {
  //   background-image: url("/assets/img/about/chicago.jpg");
  // }

  .about-header .seattle {
    background-image: url("../../public/assets/img/about/seattle.jpg");
  }

  .about-info {
    position: absolute;
    margin-top: -10px;
    border-radius: 10px;
    //background: var(--ion-background-color, #fff);
  }

  .about-info h3 {
    margin-top: 0;
  }

  .about-info ion-list {
    padding-top: 0;
  }

  .about-info p {
    line-height: 130%;

    //color: var(--ion-color-dark);
  }

  .about-info ion-icon {
    margin-inline-end: 32px;
  }

  /*
   * iOS Only
   */

  .ios .about-info {
    --ion-padding: 19px;
  }

  .ios .about-info h3 {
    font-weight: 700;
  }

  p{
    font-size: 14.5px;
    //color: var(--ion-color-step-600, #60646b);
    margin-left: 20px;
    margin-right: 15px;
    text-align: justify;

    b {
      line-height: 0.75;
      font-weight: 600;
      font-size: larger;
      color: var(--ion-text-color, #352f2f);
    }
  }

}

#date-input-popover {
  --offset-y: -var(--ion-safe-area-bottom);

  --max-width: 90%;
  --width: 336px;
}
