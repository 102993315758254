:root {
  
  ion-popover {
    //width:700px;
    --min-width: 95%;
    //size: 100%;
    color: var(--ion-text-color, #796767);
  } 
  
  ion-popover::part(backdrop) {
    background-color: rgb(106, 106, 206);
  }

  .detail-col ion-col {
    border-color: rgb(216, 204, 204); 
    border-width: .01em; 
    border-style:solid; 
    border-radius: 0.5em;
    //height: 4em;
  }

  .no-margin {
    height: 2em;
  }
 
}
  
