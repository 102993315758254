#login-page,
#signup-page,
#support-page,
#account-page,
#address-page,
#termsAndConditions-page,
#faq-page,
#about-page {
  .login-title {
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .login-sign-up,
  .login-sign-in {
    margin-top: 2rem;
    font-family: Poppins;
    font-size: 1rem;
    text-align: center;
    align-items: center;
  }

  .list {
    margin-bottom: 0;
  }

  .input-list {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .sign-in-button,
  .sign-in-button,
  .send-in-button,
  .save-button {
    margin-top: 2rem;
    width: 100%;
    height: 4em;
  }

  .terms-button {
    // margin-top: 2rem;
    width: 50%;
    height: 3em;
    background-color: rgb(27, 26, 26);
    color: black;
  }

  .input-text {
    margin-top: 2rem;
    height: 3em;
    background-color: #F2F2F2 !important;
  }

  .input-text-tight {
    margin-top: 1rem;
    height: 3em;
    background-color: #F2F2F2 !important;
  }

  .input-select {
    margin-top: 1rem;
    margin-right: 3rem;
    height: 3em;
    width: 50%;
    background-color: #F2F2F2 !important;
  }

  .input-text-no-top-margin {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 3em;
    background-color: #F2F2F2 !important;
  }

  .input-checkbox {
    margin-top: 1rem;
    height: 3em;
    background-color: #F2F2F2 !important;
  }

  .input-link {
    margin-top: 0rem;
    height: 5em;
  }

  .no-click {
    pointer-events: none; //This makes it not clickable
  }

  ion-item {
    --background-hover: transparent;
  }

  .no-ripple {
    --ripple-color: transparent;
    --background-activated: transparent;
  }

  ion-input {
    --padding-start: 10px;

    input::placeholder {
      height: 2em;
      background-color: #F2F2F2 !important;
    }
  }

  p {
    text-align: justify;
  }

  .faq p {
    font-size: 14px;
    color: var(--ion-color-step-600, #60646b);
    margin-left: 20px;
    margin-right: 15px;
    text-align: justify;

    b {
      line-height: 0.5;
      color: var(--ion-text-color, #000000);
    }
  }

  .account p {
    line-height: 0.5;
  }

  .account-label {
    margin-bottom: 0rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
    padding: 0rem;
  }
}
