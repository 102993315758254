#order-list {
  .order-card {
    display: flex;
    flex-direction: column;
    width: 100%;

  }

  /* Due to the fact the cards are inside of columns the margins don't overlap
   * properly so we want to remove the extra margin between cards
   */
  ion-col:not(:last-of-type) .order-card {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }

  ion-row {
    width: 100%;
  }

  ion-col {
    //border-color: black; 
    //border-width: .01em; 
    //border-style:solid; 
    width: 100%;
    height: 100%;
  }

  .no-margin{
    margin: 0px;
  }

  .empty-order ion-img {
    max-height: 40%;
    max-width: 40%;
    margin: 36px 50px;
    //pointer-events: none;
    left: 50%;
    transform: translateX(40%);
  }

  ion-button {
    width: 100%;
    height: 40px;
    position: relative;
  }

  .order-card ion-button {
    width: 35px;
    height: 30px;
    color: "white";
    left: 15px;
  }

  .order-card .order-item {
    --min-height: 85px;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .order-card .order-item h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .order-card .order-item p {
    font-size: 13px;
    letter-spacing: 0.02em;
  }

  .order-card ion-card-header {
    padding: 0;
  }

  .order-card ion-card-content {
    flex: 1 1 auto;
    padding: 0;
  }

  .ios ion-list {
    margin-bottom: 10px;
  }

  .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);

    padding: 0;
  }

  .img{
    width: 20px;
    height: 20px;
  }

  p {
    //padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
    //left: 50%;
    transform: translateX(25%);
    b {
      color: var(--ion-text-color, #000000);
    }
  }

  h3 {
    margin-top: 5px;
  }
  
  .right-h3 {
    text-align: right;
  }
}